<template>
    <a-card :brodered="false">
          <a-button size='small' @click="()=>{$router.go(-1)}">
            <LeftOutlined />返回
          </a-button>
          <a-form :labelCol="{span:3}" :wrapperCol="{span:18}" class="m-t-40" :model="form" :rules="rules" ref="formRef">
              <a-form-item label="省" required>
                   <a-input placeholder="请输入省份" disabled v-model:value="form.province" style="width:220px"></a-input>
              </a-form-item>
              <a-form-item label="市" help="为保证数据完整，请输入正确的城市名称" class="m-t-40" name="city">
                   <a-input placeholder="请输入城市名称" v-model:value="form.city" style="width:220px" :disabled="$route.query.name=='area'"></a-input>
              </a-form-item>
               <a-form-item label="县/区" help="为保证数据完整，请输入正确的县/城名称" required class="m-t-20" name="area" v-if="$route.query.name=='area'">
                   <a-input placeholder="请输入县/区名称" v-model:value="form.area" style="width:220px" ></a-input>
              </a-form-item>
              <a-form-item label="行政编码" class="m-t-20" name="id">
                   <a-input placeholder="请输入行政编号" v-model:value="form.id" :disabled="$route.query.type!='add'" :maxlength="6" style="width:220px"></a-input>
              </a-form-item>
              <a-form-item label="邮政编码" class="m-t-40">
                   <a-input placeholder="请输入邮政编码" v-model:value="form.zip_code" style="width:220px" :maxlength="6"></a-input>
              </a-form-item>
              <a-form-item label="经纬度" class="m-t-40">
                   <a-input placeholder="请输入经度" v-model:value="form.lng" style="width:220px"></a-input>
                   <a-input placeholder="请输入纬度" v-model:value="form.lat" class="m-l-20" style="width:220px"></a-input>
              </a-form-item>
              <a-form-item label="备注" class="m-t-40">
                   <a-textarea placeholder="请输入备注" v-model:value="form.remarks" style="width:460px;min-height:150px" :maxlength="100"></a-textarea>
              </a-form-item>
              <a-form-item  class="m-t-60" :wrapperCol="{span:18,offset:3}">
                  <a-button type="primary" @click="sumbit()">保存</a-button>
              </a-form-item>
          </a-form>
    </a-card>
</template>
<script>
import {areaAdd,areaDetail,areaUpdate} from '@/api/system.js';
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref,reactive,toRefs, onMounted,inject } from 'vue';
import { message } from 'ant-design-vue';
import { $iscode } from "@/utils/app";
export default{
    components:{
        LeftOutlined
    },
    setup(){
        const $router=inject("$router")
        const $route=inject("$route")
        const form = ref({
            province:'',
            city:'',
            id:'',
            zip_code:'',
            lng:'',
            lat:'',
            remarks:'',
            area:'',
            pid:''
        })
        const formRef=ref(null)
        
        const state = reactive({
            rules:{
               city:[{required: true,message: '请输入城市名称!'}],
               id:[{required: true,message: '请输入行政编号!'}]
            }
        })
        const sumbit = function(){
             formRef.value.validate().then(async ()=>{
                 let res;
                  let obj = {};
                 if($route.query.type == 'add'){
                     if($route.query.name=='city'){
                         obj = {
                             ...form.value,
                             name:form.value.city,
                             pid:$route.query.id
                         }
                     }else if($route.query.name=='area'){
                         obj = {
                             ...form.value,
                             name:form.value.area,
                             pid:$route.query.id
                         }
                     }
                     res = await areaAdd({...obj}).then(res=>res.data);
                 }else{
                     if($route.query.name=='city'){
                         obj = {
                             ...form.value,
                             name:form.value.city
                         }
                     }else if($route.query.name=='area'){
                         obj = {
                             ...form.value,
                             name:form.value.area
                         }
                     }
                    res = await areaUpdate({...obj}).then(res=>res.data);
                 }

                 if($iscode(res,true)){
                    $router.go(-1)
                 }
             }).catch(err=>{
                 message.error('完善表单!')
             })
        }
        
       
        const getAreaDetail = async function(){
            const hide = message.loading('加载中...')
            let res = await areaDetail({id:$route.query.id}).then(res=>res.data)
            setTimeout(hide)
            if($iscode(res)){
                // console.log(res)
               if($route.query.type=='edit'){
                      form.value = Object.assign(form.value,res.data)
                      if($route.query.name=='city'){
                        form.value.city = res.data.name;
                        form.value.province = res.data.parent?res.data.parent.province.name:'';
                      }else if($route.query.name=='area'){
                          form.value.area = res.data.name;
                          form.value.province = res.data.parent?res.data.parent.province.name:'';
                          form.value.city = res.data.parent?res.data.parent.city.name:'';
                      }
               }else{
                   if($route.query.name=='city'){
                      form.value.province = res.data.name;
                   }else if($route.query.name=='area'){
                       form.value.city = res.data.name;
                       form.value.province = res.data.parent?res.data.parent.province.name:'';
                   }
               }
            }else{
              message.error(res.msg)
            }
        }

        onMounted(()=>{
             getAreaDetail()
        })

        return {
            form,
            ...toRefs(state),
            sumbit,
            formRef
        }
    }
}
</script>
